// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import EditUserBill from '@/components/EditUserBill.vue'
import { userBillFactory } from '@/http/user-bill'
import { masterDataFactory } from '@/http/master-data'
import swal from 'sweetalert'
import constants from '@/constants/constants'
import Utils from '@/utils/utils'
import token from '@/constants/token'

@Options({
  components: {
    Header,
    EditUserBill
  },
  data() {
    return {
      userBills: [],
      statuses: [],
      stats: {
        total_bill: 0,
        total_paid: 0
      },
      months: [],
      schoolYears: [],
      math_operations: [],
      school: {
        name: ''
      },
      totalUnpaid: 0,
      readMoreActivated: false,
      param: {
        school_year_id: 0
      },
      user_id: this.$route.params.user_id,
      data: {
        bill_name: '',
        bill_month: '',
        details: [],
        total_amount : 0,
        fine_rules: {
          value: 0,
          type: 'nominal',
          formula: 'flat'
        },
        description: '',
        due_date: null,
        dateline_date: null,
        is_published: '',
        state: '',
        user_updated: {
          name: ''
        },
        updated_at: '',
        log: []
      },
      data_master: {
        school_years: "1",
        months: "1",
        school: "1",
        math_operations: "1"
      },
      user: {
        name: '',
        class_name: '',
        parents_father: '',
        parents_mother: '',
        user_profile_no_hp: '',
        student_profile_nis: ''
      },
      currentSort:'name',
      currentSortDir:'asc',
      path: process.env.VUE_APP_STORAGE_PATH,
      imagepath: '',
      subdomain: token.user.subdomain,
      constants: constants,
      searchField: "",
    }
  },
  methods: {
    onImageLoadFailure: function(event:any) {
      event.target.src = '/img/photos/default-avatar.png'
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    edit: function(type: string, bill_id: any, bill_user_id: any) {
      Promise.resolve(userBillFactory.billUserDetail(bill_id, bill_user_id))
      .then(result => {
          var bill_user = result.data.bill_user
          var data = {
            id: bill_user.id,
            spp_bill_id: bill_user.spp_bill_id,
            school_year_id: bill_user.school_year_id,
            bill_name: bill_user.bill.name,
            bill_month: this.getMonth(bill_user.id),
            active_date: bill_user.active_date.substring(0, 10),
            deadline_date: new Date(bill_user.deadline_date.substring(0, 10)),
            due_date: new Date(bill_user.due_date.substring(0, 10)),
            total_amount: bill_user.total_amount,
            details: bill_user.details,
            description: bill_user.bill.description,
            is_published: (bill_user.is_published == 1) ? true : false,
            fine_rules: bill_user.bill.fine_rules,
            user_updated: bill_user.user_updated,
            log: bill_user.log,
            updated_at: Utils.beautifyDatetime(bill_user.updated_at)
          }
          if (bill_user.bill.fine_rules.length == 0) {
            var fine_rules = {
              value: 0,
              type: 'nominal',
              formula: 'flat'
            }
            data.fine_rules = fine_rules
          }
          this.data = data
          this.data.state = type
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadData: function() {
      Promise.all([
        userBillFactory.userBill(this.param, this.user_id),
      ]).then(results => {
        if (results[0].data.user_bills.length > 0) {
          var userData = results[0].data.user
          var user = {
            user_id: ('user_id' in userData) ? userData.user_id : 0,
            name: ('name' in userData) ? userData.name : '',
            class_name: ('class' in userData) ? ('name' in userData.class) ? userData.class.name : '' : '',
            parent_father: ('parents' in userData) ? ('father' in userData.parents) ? userData.parents.father : '' : '',
            parent_mother: ('parents' in userData) ? ('mother' in userData.parents) ? userData.parents.mother : '' : '',
            user_profile_no_hp: ('user_profile' in userData) ? ('no_hp' in userData.user_profile) ? userData.user_profile.no_hp : '' : '',
            student_profile_nis: ('student_profile' in userData) ? ('nis' in userData.student_profile) ? userData.student_profile.nis : '' : ''
          }
          this.user = user
          this.userBills = results[0].data.user_bills
          this.statuses = results[0].data.statuses
          this.stats = results[0].data.stats
          this.userBills.forEach((detail:any) => {
            const d = detail.id.split("-")
            detail.bill_month = parseInt(d[2])
            detail.bill_name = detail.bill.name
            detail.total_payment = detail.total_amount + detail.late_charge
            detail.total_unpaid = detail.total_payment - detail.total_paid
            detail.deadline_date = detail.deadline_date.substring(0, 10)
            detail.due_date = detail.due_date.substring(0, 10)
            detail.active_date = detail.active_date.substring(0, 10)
          })
          this.totalUnpaid = (this.stats.total_bill - this.stats.total_paid)
          var path = this.path + this.subdomain + '/student_photos/' + this.user.student_profile_nis + '.jpg'
          this.imagepath = path
        } else {
          this.userBills = []
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      Promise.all([
        masterDataFactory.master_datas(this.data_master),
      ]).then(results => {
        this.schoolYears = results[0].data.school_years
        this.months = results[0].data.months
        this.school = results[0].data.school
        this.math_operations = results[0].data.math_operations
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
          }
        })
        this.loadData()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    sort:function(s:any) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc'
      }
      this.currentSort = s
    },
    publishByID: function(bill:any, e:any) {
      swal({
        title: "Apakah anda yakin?",
        text:  (!bill.is_published) ? "Tagihan " + bill.bill.name + " akan ditampilkan ke pengguna." : "Tagihan " + bill.bill.name + " batal ditampilkan ke pengguna.",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((willGenerate) => {
        if (willGenerate) {
          if (this.searchField.length == 0) {
            this.param.user_id = ''
          }
          if (this.param.user_id != '') {
            this.param.class_id = ''
          }
          var param = {
            is_published: !bill.is_published,
            school_year_id: this.param.school_year_id,
            spp_bill_user_id: bill.id
          }
          Promise.resolve(userBillFactory.userBillPublish(param))
          .then(results => {
            if (results.error == false) {
              if (!bill.is_published) {
                swal("Tagihan " + bill.bill.name + " ditampilkan ke " + bill.user_name + ".", "", "success")
              } else {
                swal("Tagihan " + bill.bill.name + " batal ditampilkan ke " + bill.user_name + ".", "", "success")
              }
              this.loadData()
            } else if (results.response.data.error == true) {
              var errorMessage = results.response.data.messages
              var msg = ''
              for (let i in errorMessage) {
                msg += errorMessage[i] + "<br>"
              }
              var length = 100
              msg = msg.substring(0, length)
              swal({
                text: (!bill.is_published) ? "Gagal menampilkan tagihan ke user" : "Gagal membatalkan tagihan ke user",
                icon: "error",
                content: {
                  element: "p",
                  attributes: {
                    innerHTML: msg
                  }
                }
              })
            }
          }).catch((e) => {
            swal("Gagal melakukan request", "", "error")
            console.log(e)
          })
        } else {
          var checkbox = e.target
          checkbox.checked = !checkbox.checked
        }
      })
      return
    }
  },
  computed: {
    sortedUserBills: function() {
      return this.userBills.sort((a:any,b:any) => {
        let modifier = 1
        if(this.currentSortDir === 'desc') modifier = -1
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier
        return 0
      })
    }
  },
  async mounted () {

    await this.loadDataMaster()

  }
})

export default class BillDetail extends Vue {}
